import React, {ChangeEventHandler, ReactElement, useState} from "react";
import {Asset, Business} from "@devour/client";
import {FileWithSRC} from "../../../utils/renderAssetsHelper";
import {isAsset} from "../../../utils/typeGuards";
import {CreateBusinessBodyFrontend, UpdatedBusinessBodyFrontend} from "./BusinessFormValues";
import FileInputButton from "../../../components/inputs/FileInputButton";
import FrameButton from "../../../components/buttons/FrameButton";
import {FaImages, FaPlus} from "react-icons/fa";
import {FiX} from "react-icons/fi";


interface Props {
    businessBody: CreateBusinessBodyFrontend | UpdatedBusinessBodyFrontend;
    onImageChange: (key: "icon" | "headerImage") => ChangeEventHandler<HTMLInputElement>;
    onImageRemove: (key: "icon" | "headerImage") =>void;
    inputOnChange: (key: keyof Pick<CreateBusinessBodyFrontend, "description">) => ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
}

function BusinessDetailsHeader(props: Props): ReactElement {
    const [editingDescription, setEditingDesciption] = useState<boolean>(false);

    function generateImageUrl(image: Asset | FileWithSRC): string {
        if (!image) {
            return;
        }

        if (isAsset(image)) {
            return (image as Asset).url;
        }
        return (image as FileWithSRC).imageSRC as string;
    }

    return(
        <div className="business-page_header">
            <div className="business-page_section-margin business-page_header_image_button-container">
                <FileInputButton
                    accept=".png, .jpg, .jpeg"
                    multiple={false}
                    onChange={props.onImageChange("headerImage")}
                >
                    <FrameButton
                        color="white-drop-shadow"
                        size="narrow"
                        className="business-page_header_image_button"
                    >
                        Update Image
                    </FrameButton>
                </FileInputButton>
                {props.businessBody?.headerImage && (
                        <FiX
                            className="business-page_header_image_remove-button"
                            onClick={() => props.onImageRemove("headerImage")}
                        />
                    )}
            </div>
            <div className="business-page_header_image">
                {props.businessBody?.headerImage ? (
                    <>
                        <img
                            src={generateImageUrl(props.businessBody?.headerImage)}
                            alt={props.businessBody?.name}
                        />
                    </>
                ) : (
                    <FaImages className="business-page_header_image_background-icon"/>
                )}

            </div>
            <div className="business-page_header_logo-container business-page_section-margin">
                <div>
                    <FileInputButton
                        accept=".png, .jpg, .jpeg"
                        multiple={false}
                        onChange={props.onImageChange("icon")}
                    >
                        <div className="business-page_header_logo">
                            {props.businessBody?.icon ? (
                                <img
                                    src={generateImageUrl(props.businessBody?.icon)}
                                    alt={props.businessBody?.name}
                                />
                            ) : (
                                <FaImages className="business-page_header_logo_background-icon"/>
                            )}
                        </div>
                    </FileInputButton>
                    {props.businessBody?.icon && <span onClick={() => props.onImageRemove("icon")}>Remove Icon</span>}
                </div>
            </div>
            <div className="business-page_header_info business-page_section-margin">
                <h2>{props.businessBody?.name}</h2>
                {!editingDescription ? (
                    <p className="business-page_header_info_description">
                        {props.businessBody?.description}
                        <span
                            className="business-page_header_info_description_edit-btn"
                            onClick={() => setEditingDesciption(true)}
                        >
                            {props.businessBody?.description ? " Edit" : "Add description"}
                        </span>
                    </p>
                ) : (
                    <div className="business-page_header_info_description-update">
                        <textarea
                            rows={1}
                            placeholder="Business Description..."
                            value={props.businessBody?.description}
                            onChange={props.inputOnChange("description")}
                        />
                        <FrameButton
                            color="purple"
                            size="narrow"
                            onClick={() => setEditingDesciption(false)}
                        >
                            Save
                        </FrameButton>
                    </div>
                )}
            </div>
        </div>
    )
}

export default BusinessDetailsHeader;
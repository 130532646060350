"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.PosToJSON = exports.PosFromJSONTyped = exports.PosFromJSON = void 0;
var runtime_1 = require("../runtime");
function PosFromJSON(json) {
    return PosFromJSONTyped(json, false);
}
exports.PosFromJSON = PosFromJSON;
function PosFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
        'name': json['name'],
        'description': !(0, runtime_1.exists)(json, 'description') ? undefined : json['description'],
        'mealmeLabel': !(0, runtime_1.exists)(json, 'mealmeLabel') ? undefined : json['mealmeLabel'],
        'itsaCheckmateLabel': !(0, runtime_1.exists)(json, 'itsaCheckmateLabel') ? undefined : json['itsaCheckmateLabel'],
    };
}
exports.PosFromJSONTyped = PosFromJSONTyped;
function PosToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'name': value.name,
        'description': value.description,
        'mealmeLabel': value.mealmeLabel,
        'itsaCheckmateLabel': value.itsaCheckmateLabel,
    };
}
exports.PosToJSON = PosToJSON;

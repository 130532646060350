"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.RestaurantSubtypeToJSON = exports.RestaurantSubtypeFromJSONTyped = exports.RestaurantSubtypeFromJSON = exports.RestaurantSubtype = void 0;
/**
 *
 * @export
 * @enum {string}
 */
var RestaurantSubtype;
(function (RestaurantSubtype) {
    RestaurantSubtype["RESTAURANT"] = "RESTAURANT";
    RestaurantSubtype["GROCERY"] = "GROCERY";
})(RestaurantSubtype = exports.RestaurantSubtype || (exports.RestaurantSubtype = {}));
function RestaurantSubtypeFromJSON(json) {
    return RestaurantSubtypeFromJSONTyped(json, false);
}
exports.RestaurantSubtypeFromJSON = RestaurantSubtypeFromJSON;
function RestaurantSubtypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.RestaurantSubtypeFromJSONTyped = RestaurantSubtypeFromJSONTyped;
function RestaurantSubtypeToJSON(value) {
    return value;
}
exports.RestaurantSubtypeToJSON = RestaurantSubtypeToJSON;

"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.RestaurantSearchResultToJSON = exports.RestaurantSearchResultFromJSONTyped = exports.RestaurantSearchResultFromJSON = void 0;
var runtime_1 = require("../runtime");
var _1 = require("./");
function RestaurantSearchResultFromJSON(json) {
    return RestaurantSearchResultFromJSONTyped(json, false);
}
exports.RestaurantSearchResultFromJSON = RestaurantSearchResultFromJSON;
function RestaurantSearchResultFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        '_id': json['_id'],
        'name': json['name'],
        'taxonomies': !(0, runtime_1.exists)(json, 'taxonomies') ? undefined : json['taxonomies'],
        'headerImage': !(0, runtime_1.exists)(json, 'headerImage') ? undefined : json['headerImage'],
        'isOpen': json['isOpen'],
        'address': (0, _1.AddressFromJSON)(json['address']),
        'distance': !(0, runtime_1.exists)(json, 'distance') ? undefined : json['distance'],
        'url': !(0, runtime_1.exists)(json, 'url') ? undefined : json['url'],
        'timeZone': json['timeZone'],
        'phoneNumber': (0, _1.PhoneNumberFromJSON)(json['phoneNumber']),
        'prepTime': json['prepTime'],
        'distanceDeliveryMax': !(0, runtime_1.exists)(json, 'distanceDeliveryMax') ? undefined : json['distanceDeliveryMax'],
        'suspendUntil': !(0, runtime_1.exists)(json, 'suspendUntil') ? undefined : json['suspendUntil'],
        'serviceAvailabilities': (json['serviceAvailabilities'].map(_1.BusinessServiceAvailabilityFromJSON)),
        'handoffOptions': !(0, runtime_1.exists)(json, 'handoffOptions') ? undefined : (json['handoffOptions'].map(_1.HandoffOptionsFromJSON)),
        'promos': (json['promos'].map(_1.OrderDiscountSearchEssentialFromJSON)),
        'secretMenuItems': (json['secretMenuItems'].map(_1.MenuItemSearchEssentialFromJSON)),
    };
}
exports.RestaurantSearchResultFromJSONTyped = RestaurantSearchResultFromJSONTyped;
function RestaurantSearchResultToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        '_id': value._id,
        'name': value.name,
        'taxonomies': value.taxonomies,
        'headerImage': value.headerImage,
        'isOpen': value.isOpen,
        'address': (0, _1.AddressToJSON)(value.address),
        'distance': value.distance,
        'url': value.url,
        'timeZone': value.timeZone,
        'phoneNumber': (0, _1.PhoneNumberToJSON)(value.phoneNumber),
        'prepTime': value.prepTime,
        'distanceDeliveryMax': value.distanceDeliveryMax,
        'suspendUntil': value.suspendUntil,
        'serviceAvailabilities': (value.serviceAvailabilities.map(_1.BusinessServiceAvailabilityToJSON)),
        'handoffOptions': value.handoffOptions === undefined ? undefined : (value.handoffOptions.map(_1.HandoffOptionsToJSON)),
        'promos': (value.promos.map(_1.OrderDiscountSearchEssentialToJSON)),
        'secretMenuItems': (value.secretMenuItems.map(_1.MenuItemSearchEssentialToJSON)),
    };
}
exports.RestaurantSearchResultToJSON = RestaurantSearchResultToJSON;

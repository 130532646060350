import React, {ChangeEventHandler, ReactElement, FormEvent, useEffect, useState} from "react";
import {
	MenuCategory,
	CreateMenuCategoryBody,
	GetMenuResponse,
	MenusApi,
	BusinessServiceAvailability,
} from "@devour/client";
import {useDispatch} from "react-redux";
import FrameButton from "../buttons/FrameButton";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/metaActions";
import getConfig from "../../utils/getConfig";
import FrameModalHeader from "./modalComponents/FrameModalHeader";
import FrameOneModal from "./modalComponents/FrameOneModal";
import FrameModalBody from "./modalComponents/FrameModalBody";
import FrameOneSelect from "../inputs/FrameOneSelect";
import SelectOptionsFactory, {ISelectOption} from "../inputs/SelectOptionsFactory";
import FrameOneSwitchInput from "../inputs/FrameOneSwitchInput";
import ServiceAvailabilitiesInput from "../inputs/ServiceAvailabilitiesInput";
import {posLockedFields} from "../../utils/posLockedFields";

const defaultValues: CreateMenuCategoryBody = {
	business: "",
	parent: "",
	name: "",
	sortOrder: 0,
	isEnabled: true,
	serviceAvailabilities: [],
};

interface Props {
	menuPermission: boolean;
	isChowly: boolean;
	isItsaCheckmate: boolean;
	isMealme: boolean;
	restaurantMenu: GetMenuResponse;
	menuCategory: MenuCategory;
	onClose: () => void;
	onUpdate: () => void;
}

function RestaurantMenuCategoryEditModal(props: Props): ReactElement {
	const dispatch = useDispatch();
	const [formValues, setFormValues] = useState<CreateMenuCategoryBody>(defaultValues);

	useEffect(() => {
		if (props.menuCategory) {
			setFormValues({
				business: props.menuCategory.business,
				parent: props.menuCategory.parent,
				name: props.menuCategory.name,
				sortOrder: props.menuCategory.sortOrder,
				isEnabled: props.menuCategory.isEnabled,
				serviceAvailabilities: props.menuCategory.serviceAvailabilities,
			});
		}
	}, [props.menuCategory]);

	/**
	 * Handle all text input onChange events.
	 *
	 * @param key
	 */
	function inputOnChange(key: keyof CreateMenuCategoryBody): ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement> {
		return (e) => {
			setFormValues({
				...formValues,
				[key]: e.target.value,
			});
		}
	}

	async function onFormSubmit(e: FormEvent<HTMLFormElement>): Promise<void> {
		e.preventDefault();
		dispatch(incrementLoading());

		try {
			await new MenusApi(getConfig()).updateMenuCategory({
				id: props.menuCategory.id,
				createMenuCategoryBody: {
					...formValues,
					sortOrder: Number(formValues.sortOrder),
				},
			});
			setFormValues(defaultValues);
			props.onUpdate();
		} catch (e) {
			dispatch(await addError(e));
		} finally {
			dispatch(decrementLoading());
		}
	}

	function flattenCategories(options: Array<MenuCategory>, prefix: string = ""): Array<ISelectOption<string>> {
		let newMap: Array<ISelectOption<string>> = [];
		if (!options) {
			return newMap;
		}
		for (const option of options) {
			newMap.push({
				value: option.id,
				label: prefix + option.name,
			});
			if (option.menuSubcategories) {
				newMap = newMap.concat(flattenCategories(option.menuSubcategories, `${prefix + option.name} - `));
			}
		}
		return newMap;
	}

	/**
	 * Handle the service availabilities inputs onChange.
	 */
	function serviceAvailabilitiesOnChange(newAvailabilities: Array<BusinessServiceAvailability>): void {
		setFormValues({
			...formValues,
			serviceAvailabilities: newAvailabilities,
		});
	}

	/**
	 * Handle the alcohol status inputs onChange.
	 */
	function enabledStatusOnChange(): void {
		setFormValues({
			...formValues,
			isEnabled: !formValues.isEnabled,
		});
	}

	function onModalClose() {
		props.onClose();
	}

	/**
	 * Decide whether field should be disabled.
	 * @param fieldName
	 * @return Since this is a double negative of disabled where `true` means it stops working, return `false` to allow it to work.
	 */
	function isDisabled(fieldName: keyof CreateMenuCategoryBody): boolean {
		if (!props.menuPermission) {
			return true;
		}
		if (props.isChowly && posLockedFields.chowly.menuCategory.includes(fieldName)) {
			return true;
		}
		if (props.isItsaCheckmate && posLockedFields.itsaCheckmate.menuCategory.includes(fieldName)) {
			return true;
		}
		if (props.isMealme && posLockedFields.mealme.menuCategory.includes(fieldName)) {
			return true;
		}

		return false;
	}

	return (
		<FrameOneModal
			isOpen={!!props.menuCategory}
			size="sm"
			toggle={onModalClose}
			contentClassName="restaurant-menu-category-edit-modal"
		>
			<FrameModalHeader
				title="Update Menu Category"
				toggle={onModalClose}
			/>

			<FrameModalBody className="restaurant-menu-category-edit-modal_body">

				<form onSubmit={onFormSubmit}>
					<div
						className="restaurant-menu-category-edit-modal_field restaurant-menu-category-edit-modal_parent-container"
					>
						<label>Parent</label>
						<FrameOneSelect
							disabled={isDisabled("parent")}
							value={formValues.parent}
							onChange={inputOnChange("parent")}
						>
							<option value="">Menu Category</option>
							<SelectOptionsFactory
								<string>
								options={flattenCategories(props.restaurantMenu?.menus)}
							/>
						</FrameOneSelect>
					</div>

					<div
						className="restaurant-menu-category-edit-modal_field restaurant-menu-category-edit-modal_name-container"
					>
						<label>Name *</label>
						<input
							type="text"
							disabled={isDisabled("name")}
							placeholder="Menu item name..."
							value={formValues.name}
							onChange={inputOnChange("name")}
							required={true}
						/>
					</div>

					<div
						className="restaurant-menu-category-edit-modal_field restaurant-menu-category-edit-modal_price-container">
						<label>Sort Order</label>
						<input
							type="number"
							placeholder="Sort order..."
							value={formValues.sortOrder}
							onChange={inputOnChange("sortOrder")}
							disabled={isDisabled("sortOrder")}
						/>
					</div>


					<div
						className="restaurant-menu-category-edit-modal_field restaurant-menu-category-edit-modal_tax-rate-container"
					>
						<label>Active Item?</label>
						<FrameOneSwitchInput
							<"yes" | "no">
							name="isActive"
							disabled={isDisabled("isEnabled")}
							value={(formValues.isEnabled) ? "yes" : "no"}
							onToggle={enabledStatusOnChange}
							options={[
								{
									render: "Enabled",
									value: "yes",
								},
								{
									render: "Disabled",
									value: "no",
								},
							]}
						/>
					</div>

					<div
						className="restaurant-menu-category-edit-modal_field restaurant-menu-category-edit-modal_tax-rate-container"
					>
						<label>Menu Hours</label>
						<p>
							Any time restriction that exist on parent categories will be inherited.
						</p>
						<ServiceAvailabilitiesInput
							onUpdateServiceAvailabilities={serviceAvailabilitiesOnChange}
							serviceAvailabilities={formValues.serviceAvailabilities}
							noHoursText="No additional time restriction"
							disabled={isDisabled("serviceAvailabilities")}
						/>
					</div>

					{(props.menuPermission) && (
						<FrameButton
							<React.ButtonHTMLAttributes<HTMLButtonElement>>
							color="purple"
							size="normal"
							className="restaurant-menu-category-edit-modal_submit-button"
							forwardProps={{type: "submit"}}
						>
							Submit
						</FrameButton>
					)}
				</form>
			</FrameModalBody>

		</FrameOneModal>
	);
}

export default RestaurantMenuCategoryEditModal;

"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.MealmeStoreLocalHoursToJSON = exports.MealmeStoreLocalHoursFromJSONTyped = exports.MealmeStoreLocalHoursFromJSON = void 0;
var runtime_1 = require("../runtime");
var _1 = require("./");
function MealmeStoreLocalHoursFromJSON(json) {
    return MealmeStoreLocalHoursFromJSONTyped(json, false);
}
exports.MealmeStoreLocalHoursFromJSON = MealmeStoreLocalHoursFromJSON;
function MealmeStoreLocalHoursFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'operational': !(0, runtime_1.exists)(json, 'operational') ? undefined : (0, _1.MealmeHoursFromJSON)(json['operational']),
        'pickup': (0, _1.MealmeHoursFromJSON)(json['pickup']),
        'delivery': !(0, runtime_1.exists)(json, 'delivery') ? undefined : (0, _1.MealmeHoursFromJSON)(json['delivery']),
        'dine_in': (0, _1.MealmeHoursFromJSON)(json['dine_in']),
    };
}
exports.MealmeStoreLocalHoursFromJSONTyped = MealmeStoreLocalHoursFromJSONTyped;
function MealmeStoreLocalHoursToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'operational': (0, _1.MealmeHoursToJSON)(value.operational),
        'pickup': (0, _1.MealmeHoursToJSON)(value.pickup),
        'delivery': (0, _1.MealmeHoursToJSON)(value.delivery),
        'dine_in': (0, _1.MealmeHoursToJSON)(value.dine_in),
    };
}
exports.MealmeStoreLocalHoursToJSON = MealmeStoreLocalHoursToJSON;

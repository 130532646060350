import React, {ChangeEventHandler, ReactElement} from "react";
import { IoImageOutline } from "react-icons/io5";
import FrameButton from "../buttons/FrameButton";
import { FaPlus } from "react-icons/fa";
import {EditBrandMapBodyFrontend} from "../../pages/brands/components/BrandMapFormValues";
import FileInputButton from "../inputs/FileInputButton";
import {FileWithSRC} from "../../utils/renderAssetsHelper";
import {Asset} from "@devour/client";
import {isAsset} from "../../utils/typeGuards";
import { FiX } from "react-icons/fi";

interface Props {
    onChange: (key: keyof Pick<EditBrandMapBodyFrontend, "bannerImage" | "promoHero" | "promoBackground" | "promoCartHero" | "promoCartBackground">) => ChangeEventHandler<HTMLInputElement>
    onRemoveImage: (key: "bannerImage") => void;
    image?: Asset | FileWithSRC;
}

function MerchantBrandUploadBannerImage(props: Props): ReactElement {

    function generateBackgroundUrl(): string {
        if (props?.image) {
            if (isAsset(props.image)) {
                return props.image.url;
            }
            return props.image.imageSRC as string;
        }
        return process.env.REACT_APP_CDN_URL + "/images/brand-map-upload-bg.webp";
    }

    return (
        <div className="merchant-brand-title-description">
            <h4>Brand Customization</h4>
            <div className="merchant-brand-title-description_content">
                <div className="merchant-brand-title-description_content_title-container">
                    <p className="merchant-brand-title-description_content_title">Banner Image</p>
                    <div className="merchant-brand-title-description_content_tag">
                        <p>Recommended</p>
                    </div>
                </div>
                <p className="merchant-brand-title-description_content_subtitle">
                    This image will appear on the top of your brand page. For the best results on all devices, use an image that’s at least 1280 x 375 in resolution.
                </p>

                <div
                    className="merchant-brand-upload-image"
                    style={{
                        backgroundImage: `url(${generateBackgroundUrl()})`
                    }}
                >
                    {!props?.image ? (
                        <>
                            <p className="merchant-brand-upload-image_subtitle">Optional</p>
                            <IoImageOutline className="merchant-brand-upload-image_icon" />
                        </>
                    ) : (
                        <FiX
                            className="create-map-page_img-remove-icon"
                            onClick={() => props.onRemoveImage("bannerImage")}
                        />
                    )}
                    <FileInputButton
                        accept=".png, .jpg, .jpeg"
                        multiple={false}
                        onChange={props.onChange("bannerImage")}
                    >
                        <FrameButton
                            color="white-drop-shadow"
                            size="narrow"
                            className="merchant-brand-upload-image_button"
                            leftIcon={FaPlus}
                            onClick={() => {}}
                        >
                            Upload Image
                        </FrameButton>
                    </FileInputButton>

                    <div className="merchant-brand-upload-image_triangle-left" />
                    <div className="merchant-brand-upload-image_triangle-right" />

                </div>

            </div>
        </div>
    );
}

export default MerchantBrandUploadBannerImage;
"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.MenuCustomizationItemToJSON = exports.MenuCustomizationItemFromJSONTyped = exports.MenuCustomizationItemFromJSON = void 0;
var runtime_1 = require("../runtime");
var _1 = require("./");
function MenuCustomizationItemFromJSON(json) {
    return MenuCustomizationItemFromJSONTyped(json, false);
}
exports.MenuCustomizationItemFromJSON = MenuCustomizationItemFromJSON;
function MenuCustomizationItemFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
        'menuCustomization': json['menuCustomization'],
        'image': !(0, runtime_1.exists)(json, 'image') ? undefined : (0, _1.AssetFromJSON)(json['image']),
        'name': json['name'],
        'description': !(0, runtime_1.exists)(json, 'description') ? undefined : json['description'],
        'price': json['price'],
        'taxRate': json['taxRate'],
        'minAllowed': !(0, runtime_1.exists)(json, 'minAllowed') ? undefined : json['minAllowed'],
        'maxAllowed': !(0, runtime_1.exists)(json, 'maxAllowed') ? undefined : json['maxAllowed'],
        'isAlcohol': !(0, runtime_1.exists)(json, 'isAlcohol') ? undefined : json['isAlcohol'],
        'isEnabled': !(0, runtime_1.exists)(json, 'isEnabled') ? undefined : json['isEnabled'],
        'defaultQuantity': !(0, runtime_1.exists)(json, 'defaultQuantity') ? undefined : json['defaultQuantity'],
        'sortOrder': !(0, runtime_1.exists)(json, 'sortOrder') ? undefined : json['sortOrder'],
        'customizations': !(0, runtime_1.exists)(json, 'customizations') ? undefined : (json['customizations'].map(_1.MenuCustomizationFromJSON)),
    };
}
exports.MenuCustomizationItemFromJSONTyped = MenuCustomizationItemFromJSONTyped;
function MenuCustomizationItemToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'menuCustomization': value.menuCustomization,
        'image': (0, _1.AssetToJSON)(value.image),
        'name': value.name,
        'description': value.description,
        'price': value.price,
        'taxRate': value.taxRate,
        'minAllowed': value.minAllowed,
        'maxAllowed': value.maxAllowed,
        'isAlcohol': value.isAlcohol,
        'isEnabled': value.isEnabled,
        'defaultQuantity': value.defaultQuantity,
        'sortOrder': value.sortOrder,
        'customizations': value.customizations === undefined ? undefined : (value.customizations.map(_1.MenuCustomizationToJSON)),
    };
}
exports.MenuCustomizationItemToJSON = MenuCustomizationItemToJSON;

"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ActivateMealmeBodyToJSON = exports.ActivateMealmeBodyFromJSONTyped = exports.ActivateMealmeBodyFromJSON = void 0;
var runtime_1 = require("../runtime");
function ActivateMealmeBodyFromJSON(json) {
    return ActivateMealmeBodyFromJSONTyped(json, false);
}
exports.ActivateMealmeBodyFromJSON = ActivateMealmeBodyFromJSON;
function ActivateMealmeBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'mealmeStoreId': json['mealmeStoreId'],
        'mealmeMenuId': !(0, runtime_1.exists)(json, 'mealmeMenuId') ? undefined : json['mealmeMenuId'],
        'email': json['email'],
    };
}
exports.ActivateMealmeBodyFromJSONTyped = ActivateMealmeBodyFromJSONTyped;
function ActivateMealmeBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'mealmeStoreId': value.mealmeStoreId,
        'mealmeMenuId': value.mealmeMenuId,
        'email': value.email,
    };
}
exports.ActivateMealmeBodyToJSON = ActivateMealmeBodyToJSON;

"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.BrandToJSON = exports.BrandFromJSONTyped = exports.BrandFromJSON = void 0;
var runtime_1 = require("../runtime");
var _1 = require("./");
function BrandFromJSON(json) {
    return BrandFromJSONTyped(json, false);
}
exports.BrandFromJSON = BrandFromJSON;
function BrandFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
        'name': json['name'],
        'description': !(0, runtime_1.exists)(json, 'description') ? undefined : json['description'],
        'image': !(0, runtime_1.exists)(json, 'image') ? undefined : (0, _1.AssetFromJSON)(json['image']),
        'isEnabled': json['isEnabled'],
        'promos': !(0, runtime_1.exists)(json, 'promos') ? undefined : (json['promos'].map(_1.OrderDiscountFromJSON)),
    };
}
exports.BrandFromJSONTyped = BrandFromJSONTyped;
function BrandToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'name': value.name,
        'description': value.description,
        'image': (0, _1.AssetToJSON)(value.image),
        'isEnabled': value.isEnabled,
        'promos': value.promos === undefined ? undefined : (value.promos.map(_1.OrderDiscountToJSON)),
    };
}
exports.BrandToJSON = BrandToJSON;

"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.MealmeWebhookBodyToJSON = exports.MealmeWebhookBodyFromJSONTyped = exports.MealmeWebhookBodyFromJSON = void 0;
var runtime_1 = require("../runtime");
function MealmeWebhookBodyFromJSON(json) {
    return MealmeWebhookBodyFromJSONTyped(json, false);
}
exports.MealmeWebhookBodyFromJSON = MealmeWebhookBodyFromJSON;
function MealmeWebhookBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'order_id': json['order_id'],
        'chat_id': !(0, runtime_1.exists)(json, 'chat_id') ? undefined : json['chat_id'],
        'event': json['event'],
    };
}
exports.MealmeWebhookBodyFromJSONTyped = MealmeWebhookBodyFromJSONTyped;
function MealmeWebhookBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'order_id': value.order_id,
        'chat_id': value.chat_id,
        'event': value.event,
    };
}
exports.MealmeWebhookBodyToJSON = MealmeWebhookBodyToJSON;

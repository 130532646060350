"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateWithdrawBodyToJSON = exports.CreateWithdrawBodyFromJSONTyped = exports.CreateWithdrawBodyFromJSON = void 0;
function CreateWithdrawBodyFromJSON(json) {
    return CreateWithdrawBodyFromJSONTyped(json, false);
}
exports.CreateWithdrawBodyFromJSON = CreateWithdrawBodyFromJSON;
function CreateWithdrawBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'amount': json['amount'],
        'wallet': json['wallet'],
    };
}
exports.CreateWithdrawBodyFromJSONTyped = CreateWithdrawBodyFromJSONTyped;
function CreateWithdrawBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'amount': value.amount,
        'wallet': value.wallet,
    };
}
exports.CreateWithdrawBodyToJSON = CreateWithdrawBodyToJSON;

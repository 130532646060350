"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ServiceFeeTypeToJSON = exports.ServiceFeeTypeFromJSONTyped = exports.ServiceFeeTypeFromJSON = exports.ServiceFeeType = void 0;
/**
 *
 * @export
 * @enum {string}
 */
var ServiceFeeType;
(function (ServiceFeeType) {
    ServiceFeeType["DEVOURGOSERVICE"] = "DEVOURGO_SERVICE";
    ServiceFeeType["PROMOSERVICEREDUCTION"] = "PROMO_SERVICE_REDUCTION";
    ServiceFeeType["MEALMESMALLORDERFLAT"] = "MEALME_SMALL_ORDER_FLAT";
    ServiceFeeType["MEALMESMALLORDERPERCENTAGE"] = "MEALME_SMALL_ORDER_PERCENTAGE";
    ServiceFeeType["MEALMESERVICEFLAT"] = "MEALME_SERVICE_FLAT";
    ServiceFeeType["MEALMESERVICEFLATTAX"] = "MEALME_SERVICE_FLAT_TAX";
    ServiceFeeType["MEALMESERVICEPERCENTAGE"] = "MEALME_SERVICE_PERCENTAGE";
    ServiceFeeType["MEALMESERVICEPERCENTAGETAX"] = "MEALME_SERVICE_PERCENTAGE_TAX";
    ServiceFeeType["MEALMESERVICEMIN"] = "MEALME_SERVICE_MIN";
    ServiceFeeType["MEALMESERVICEMINTAX"] = "MEALME_SERVICE_MIN_TAX";
    ServiceFeeType["MEALMESERVICEMAXREDUCTION"] = "MEALME_SERVICE_MAX_REDUCTION";
    ServiceFeeType["MEALMESERVICEMAXREDUCTIONTAX"] = "MEALME_SERVICE_MAX_REDUCTION_TAX";
})(ServiceFeeType = exports.ServiceFeeType || (exports.ServiceFeeType = {}));
function ServiceFeeTypeFromJSON(json) {
    return ServiceFeeTypeFromJSONTyped(json, false);
}
exports.ServiceFeeTypeFromJSON = ServiceFeeTypeFromJSON;
function ServiceFeeTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.ServiceFeeTypeFromJSONTyped = ServiceFeeTypeFromJSONTyped;
function ServiceFeeTypeToJSON(value) {
    return value;
}
exports.ServiceFeeTypeToJSON = ServiceFeeTypeToJSON;

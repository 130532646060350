"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.MenuOrderStatusToJSON = exports.MenuOrderStatusFromJSONTyped = exports.MenuOrderStatusFromJSON = exports.MenuOrderStatus = void 0;
/**
 *
 * @export
 * @enum {string}
 */
var MenuOrderStatus;
(function (MenuOrderStatus) {
    MenuOrderStatus["CART"] = "CART";
    MenuOrderStatus["CHECKOUT"] = "CHECKOUT";
    MenuOrderStatus["PAYMENT"] = "PAYMENT";
    MenuOrderStatus["ERRORPAYMENT"] = "ERROR_PAYMENT";
    MenuOrderStatus["PAID"] = "PAID";
    MenuOrderStatus["PROCESSING"] = "PROCESSING";
    MenuOrderStatus["RECEIVED"] = "RECEIVED";
    MenuOrderStatus["DELIVERYSERVICE"] = "DELIVERY_SERVICE";
    MenuOrderStatus["DRIVERPICKUP"] = "DRIVER_PICKUP";
    MenuOrderStatus["OUTFORDELIVERY"] = "OUT_FOR_DELIVERY";
    MenuOrderStatus["DRIVERATDESTINATION"] = "DRIVER_AT_DESTINATION";
    MenuOrderStatus["DELIVERED"] = "DELIVERED";
    MenuOrderStatus["CUSTOMERPICKUP"] = "CUSTOMER_PICKUP";
    MenuOrderStatus["CUSTOMERPICKEDUP"] = "CUSTOMER_PICKED_UP";
    MenuOrderStatus["OPENISSUE"] = "OPEN_ISSUE";
    MenuOrderStatus["RESOLVED"] = "RESOLVED";
    MenuOrderStatus["ERRORDEVOUR"] = "ERROR_DEVOUR";
    MenuOrderStatus["ERRORRESTAURANT"] = "ERROR_RESTAURANT";
    MenuOrderStatus["ERRORMEALME"] = "ERROR_MEALME";
    MenuOrderStatus["ERRORDELIVERY"] = "ERROR_DELIVERY";
    MenuOrderStatus["SYSTEMCANCELLED"] = "SYSTEM_CANCELLED";
    MenuOrderStatus["CUSTOMERCANCELLED"] = "CUSTOMER_CANCELLED";
    MenuOrderStatus["CHATINITIATED"] = "CHAT_INITIATED";
    MenuOrderStatus["REFUNDED"] = "REFUNDED";
    MenuOrderStatus["AUTOREFUNDED"] = "AUTO_REFUNDED";
})(MenuOrderStatus = exports.MenuOrderStatus || (exports.MenuOrderStatus = {}));
function MenuOrderStatusFromJSON(json) {
    return MenuOrderStatusFromJSONTyped(json, false);
}
exports.MenuOrderStatusFromJSON = MenuOrderStatusFromJSON;
function MenuOrderStatusFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.MenuOrderStatusFromJSONTyped = MenuOrderStatusFromJSONTyped;
function MenuOrderStatusToJSON(value) {
    return value;
}
exports.MenuOrderStatusToJSON = MenuOrderStatusToJSON;

"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.AccountLevelToJSON = exports.AccountLevelFromJSONTyped = exports.AccountLevelFromJSON = void 0;
var _1 = require("./");
function AccountLevelFromJSON(json) {
    return AccountLevelFromJSONTyped(json, false);
}
exports.AccountLevelFromJSON = AccountLevelFromJSON;
function AccountLevelFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'level': json['level'],
        'totalExperience': json['totalExperience'],
        'experienceRequiredForNextLevel': json['experienceRequiredForNextLevel'],
        'experienceGainedAtThisLevel': json['experienceGainedAtThisLevel'],
        'userOwnsIndustryCollectionNft': json['userOwnsIndustryCollectionNft'],
        'userRequiresIndustryCollectionNftToLevelUp': json['userRequiresIndustryCollectionNftToLevelUp'],
        'transactions': (json['transactions'].map(_1.AccountExperienceTransactionFromJSON)),
    };
}
exports.AccountLevelFromJSONTyped = AccountLevelFromJSONTyped;
function AccountLevelToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'level': value.level,
        'totalExperience': value.totalExperience,
        'experienceRequiredForNextLevel': value.experienceRequiredForNextLevel,
        'experienceGainedAtThisLevel': value.experienceGainedAtThisLevel,
        'userOwnsIndustryCollectionNft': value.userOwnsIndustryCollectionNft,
        'userRequiresIndustryCollectionNftToLevelUp': value.userRequiresIndustryCollectionNftToLevelUp,
        'transactions': (value.transactions.map(_1.AccountExperienceTransactionToJSON)),
    };
}
exports.AccountLevelToJSON = AccountLevelToJSON;

"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.MealmeHoursToJSON = exports.MealmeHoursFromJSONTyped = exports.MealmeHoursFromJSON = void 0;
function MealmeHoursFromJSON(json) {
    return MealmeHoursFromJSONTyped(json, false);
}
exports.MealmeHoursFromJSON = MealmeHoursFromJSON;
function MealmeHoursFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'Monday': json['Monday'],
        'Tuesday': json['Tuesday'],
        'Wednesday': json['Wednesday'],
        'Thursday': json['Thursday'],
        'Friday': json['Friday'],
        'Saturday': json['Saturday'],
        'Sunday': json['Sunday'],
    };
}
exports.MealmeHoursFromJSONTyped = MealmeHoursFromJSONTyped;
function MealmeHoursToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'Monday': value.Monday,
        'Tuesday': value.Tuesday,
        'Wednesday': value.Wednesday,
        'Thursday': value.Thursday,
        'Friday': value.Friday,
        'Saturday': value.Saturday,
        'Sunday': value.Sunday,
    };
}
exports.MealmeHoursToJSON = MealmeHoursToJSON;

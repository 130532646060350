"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.UpdateBusinessMealmeBodyToJSON = exports.UpdateBusinessMealmeBodyFromJSONTyped = exports.UpdateBusinessMealmeBodyFromJSON = void 0;
var runtime_1 = require("../runtime");
function UpdateBusinessMealmeBodyFromJSON(json) {
    return UpdateBusinessMealmeBodyFromJSONTyped(json, false);
}
exports.UpdateBusinessMealmeBodyFromJSON = UpdateBusinessMealmeBodyFromJSON;
function UpdateBusinessMealmeBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'mealmeStoreId': !(0, runtime_1.exists)(json, 'mealmeStoreId') ? undefined : json['mealmeStoreId'],
        'mealmeMenuId': !(0, runtime_1.exists)(json, 'mealmeMenuId') ? undefined : json['mealmeMenuId'],
        'menuSource': !(0, runtime_1.exists)(json, 'menuSource') ? undefined : json['menuSource'],
    };
}
exports.UpdateBusinessMealmeBodyFromJSONTyped = UpdateBusinessMealmeBodyFromJSONTyped;
function UpdateBusinessMealmeBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'mealmeStoreId': value.mealmeStoreId,
        'mealmeMenuId': value.mealmeMenuId,
        'menuSource': value.menuSource,
    };
}
exports.UpdateBusinessMealmeBodyToJSON = UpdateBusinessMealmeBodyToJSON;

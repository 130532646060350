"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.MealmeStoreAddressToJSON = exports.MealmeStoreAddressFromJSONTyped = exports.MealmeStoreAddressFromJSON = void 0;
var runtime_1 = require("../runtime");
function MealmeStoreAddressFromJSON(json) {
    return MealmeStoreAddressFromJSONTyped(json, false);
}
exports.MealmeStoreAddressFromJSON = MealmeStoreAddressFromJSON;
function MealmeStoreAddressFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'street_addr': json['street_addr'],
        'city': json['city'],
        'state': json['state'],
        'zipcode': json['zipcode'],
        'country': json['country'],
        'street_addr_2': !(0, runtime_1.exists)(json, 'street_addr_2') ? undefined : json['street_addr_2'],
        'latitude': json['latitude'],
        'longitude': json['longitude'],
    };
}
exports.MealmeStoreAddressFromJSONTyped = MealmeStoreAddressFromJSONTyped;
function MealmeStoreAddressToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'street_addr': value.street_addr,
        'city': value.city,
        'state': value.state,
        'zipcode': value.zipcode,
        'country': value.country,
        'street_addr_2': value.street_addr_2,
        'latitude': value.latitude,
        'longitude': value.longitude,
    };
}
exports.MealmeStoreAddressToJSON = MealmeStoreAddressToJSON;

import {MenuOrderStatus} from "@devour/client";

export function parseMenuOrderStatus(status: MenuOrderStatus): string {
	switch (status) {
		case MenuOrderStatus.CART:
			return "Cart";
		case MenuOrderStatus.CHECKOUT:
			return "Checkout Page";
		case MenuOrderStatus.PAYMENT:
			return "Paying";
		case MenuOrderStatus.PAID:
			return "PAID";
		case MenuOrderStatus.PROCESSING:
			return "Order sent to restaurant POS system";
		case MenuOrderStatus.RECEIVED:
			return "Order received by restaurant POS system";
		case MenuOrderStatus.DELIVERYSERVICE:
			return "Order sent to delivery service";
		case MenuOrderStatus.DRIVERPICKUP:
			return "Driver going to pickup";
		case MenuOrderStatus.OUTFORDELIVERY:
			return "Driver enroute to customer";
		case MenuOrderStatus.DRIVERATDESTINATION:
			return "Driver at destination";
		case MenuOrderStatus.DELIVERED:
			return "Delivered";
		case MenuOrderStatus.CUSTOMERPICKUP:
			return "Ready for customer pickup";
		case MenuOrderStatus.CUSTOMERPICKEDUP:
			return "Customer picked up the order";
		case MenuOrderStatus.OPENISSUE:
			return "There is an unresolved issue with the order";
		case MenuOrderStatus.RESOLVED:
			return "Issue with the order has been resolved";
		case MenuOrderStatus.ERRORDEVOUR:
			return "There was an error submitting this order to the DevourGO platform. Please contact DevourGO support.";
		case MenuOrderStatus.ERRORRESTAURANT:
			return "There was an error submitting this order to the restaurant's POS.";
		case MenuOrderStatus.ERRORMEALME:
			return "There was an error submitting this order to the Mealme.";
		case MenuOrderStatus.ERRORDELIVERY:
			return "There was an error with the delivery service.";
		case MenuOrderStatus.ERRORPAYMENT:
			return "There was an error with collecting the customer's payment.";
		case MenuOrderStatus.SYSTEMCANCELLED:
			return "Order validation failed and was cancelled.";
		case MenuOrderStatus.CUSTOMERCANCELLED:
			return "Customer cancelled the order.";
		case MenuOrderStatus.CHATINITIATED:
			return "Customer started a live chat.";
		case MenuOrderStatus.REFUNDED:
			return "Order has been refunded.";
		case MenuOrderStatus.AUTOREFUNDED:
			return "Order has been automatically refunded.";
		default:
			return status;
	}
}

export enum MenuOrderStatusGroup {
	UNPAID = "unpaid",
	PROCESSING = "processing",
	COMPLETE = "complete",
	ERROR = "error",
}

export function parseMenuOrderStatusGroup(status: MenuOrderStatus): MenuOrderStatusGroup {
	switch (status) {
		case MenuOrderStatus.CART:
		case MenuOrderStatus.CHECKOUT:
		case MenuOrderStatus.PAYMENT:
		default:
			return MenuOrderStatusGroup.UNPAID;
		case MenuOrderStatus.PAID:
		case MenuOrderStatus.PROCESSING:
		case MenuOrderStatus.RECEIVED:
		case MenuOrderStatus.DELIVERYSERVICE:
		case MenuOrderStatus.DRIVERPICKUP:
		case MenuOrderStatus.OUTFORDELIVERY:
			return MenuOrderStatusGroup.PROCESSING;
		case MenuOrderStatus.DRIVERATDESTINATION:
		case MenuOrderStatus.DELIVERED:
		case MenuOrderStatus.CUSTOMERPICKUP:
		case MenuOrderStatus.CUSTOMERPICKEDUP:
			return MenuOrderStatusGroup.COMPLETE;
		case MenuOrderStatus.OPENISSUE:
		case MenuOrderStatus.RESOLVED:
		case MenuOrderStatus.ERRORDEVOUR:
		case MenuOrderStatus.ERRORRESTAURANT:
		case MenuOrderStatus.ERRORMEALME:
		case MenuOrderStatus.ERRORDELIVERY:
		case MenuOrderStatus.ERRORPAYMENT:
		case MenuOrderStatus.SYSTEMCANCELLED:
		case MenuOrderStatus.CUSTOMERCANCELLED:
		case MenuOrderStatus.CHATINITIATED:
		case MenuOrderStatus.REFUNDED:
		case MenuOrderStatus.AUTOREFUNDED:
			return MenuOrderStatusGroup.ERROR;
	}
}


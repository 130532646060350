import React, {ChangeEventHandler, ReactElement} from "react";
import {CreateBrandMapBodyFrontend} from "../../pages/brands/components/BrandMapFormValues";


interface Props {
    onChange: (key: keyof CreateBrandMapBodyFrontend) => ChangeEventHandler<HTMLInputElement>;
    value: string;
}

function MerchantBrandTitleDescription(props: Props): ReactElement {

    return (
        <div className="merchant-brand-title-description">
            <div className="merchant-brand-title-description_content">
                <p className="merchant-brand-title-description_content_title">
                    Title Description
                    <span className="create-map-page_optional">
						Recommended
					</span>
                </p>
                <p className="merchant-brand-title-description_content_subtitle">Give your brand map a title that captivates
                    audiences!</p>
                <input
                    placeholder="E.g. Unlock bonus rewards today!"
                    value={props.value}
                    onChange={props.onChange("name")}
                />
            </div>
        </div>
    );
}

export default MerchantBrandTitleDescription;
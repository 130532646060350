import {ConfigOptions} from "@web3modal/core/dist/_types/types/statefullCtrlTypes";
import {chains} from "@web3modal/ethereum";

export const devourConfig: ConfigOptions = {
	projectId: "ff49f3c5cdbf08b1995f2687b0858cad",
	theme: "dark",
	accentColor: "default",
	ethereum: {
		appName: "Devour",
		chains: [
			chains.mainnet,
		],
	},
}

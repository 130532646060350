"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.BusinessToJSON = exports.BusinessFromJSONTyped = exports.BusinessFromJSON = void 0;
var runtime_1 = require("../runtime");
var _1 = require("./");
var _2 = require("./");
function BusinessFromJSON(json) {
    return BusinessFromJSONTyped(json, false);
}
exports.BusinessFromJSON = BusinessFromJSON;
function BusinessFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    if (!ignoreDiscriminator) {
        if (json['type'] === 'RESTAURANT') {
            return (0, _2.RestaurantFromJSONTyped)(json, true);
        }
    }
    return {
        'id': json['id'],
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
        'type': (0, _1.BusinessTypeFromJSON)(json['type']),
        'name': json['name'],
        'internalName': !(0, runtime_1.exists)(json, 'internalName') ? undefined : json['internalName'],
        'taxonomies': !(0, runtime_1.exists)(json, 'taxonomies') ? undefined : json['taxonomies'],
        'brands': !(0, runtime_1.exists)(json, 'brands') ? undefined : json['brands'],
        'icon': !(0, runtime_1.exists)(json, 'icon') ? undefined : (0, _1.AssetFromJSON)(json['icon']),
        'headerImage': !(0, runtime_1.exists)(json, 'headerImage') ? undefined : (0, _1.AssetFromJSON)(json['headerImage']),
        'parent': !(0, runtime_1.exists)(json, 'parent') ? undefined : json['parent'],
        'stripeAccountId': !(0, runtime_1.exists)(json, 'stripeAccountId') ? undefined : json['stripeAccountId'],
        'description': !(0, runtime_1.exists)(json, 'description') ? undefined : json['description'],
        'address': (0, _1.AddressFromJSON)(json['address']),
        'currency': json['currency'],
        'email': !(0, runtime_1.exists)(json, 'email') ? undefined : json['email'],
        'distance': !(0, runtime_1.exists)(json, 'distance') ? undefined : json['distance'],
        'isEnabled': !(0, runtime_1.exists)(json, 'isEnabled') ? undefined : json['isEnabled'],
        'suspendUntil': !(0, runtime_1.exists)(json, 'suspendUntil') ? undefined : json['suspendUntil'],
        'isActive': !(0, runtime_1.exists)(json, 'isActive') ? undefined : json['isActive'],
        'website': !(0, runtime_1.exists)(json, 'website') ? undefined : json['website'],
        'url': json['url'],
        'timeZone': json['timeZone'],
        'phoneNumber': !(0, runtime_1.exists)(json, 'phoneNumber') ? undefined : (0, _1.PhoneNumberFromJSON)(json['phoneNumber']),
        'marketplacePercentageDelivery': !(0, runtime_1.exists)(json, 'marketplacePercentageDelivery') ? undefined : json['marketplacePercentageDelivery'],
        'marketplacePercentagePickup': !(0, runtime_1.exists)(json, 'marketplacePercentagePickup') ? undefined : json['marketplacePercentagePickup'],
    };
}
exports.BusinessFromJSONTyped = BusinessFromJSONTyped;
function BusinessToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'type': (0, _1.BusinessTypeToJSON)(value.type),
        'name': value.name,
        'internalName': value.internalName,
        'taxonomies': value.taxonomies,
        'brands': value.brands,
        'icon': (0, _1.AssetToJSON)(value.icon),
        'headerImage': (0, _1.AssetToJSON)(value.headerImage),
        'parent': value.parent,
        'stripeAccountId': value.stripeAccountId,
        'description': value.description,
        'address': (0, _1.AddressToJSON)(value.address),
        'currency': value.currency,
        'email': value.email,
        'distance': value.distance,
        'isEnabled': value.isEnabled,
        'suspendUntil': value.suspendUntil,
        'isActive': value.isActive,
        'website': value.website,
        'url': value.url,
        'timeZone': value.timeZone,
        'phoneNumber': (0, _1.PhoneNumberToJSON)(value.phoneNumber),
        'marketplacePercentageDelivery': value.marketplacePercentageDelivery,
        'marketplacePercentagePickup': value.marketplacePercentagePickup,
    };
}
exports.BusinessToJSON = BusinessToJSON;

"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.MenuCustomizationToJSON = exports.MenuCustomizationFromJSONTyped = exports.MenuCustomizationFromJSON = void 0;
var runtime_1 = require("../runtime");
var _1 = require("./");
function MenuCustomizationFromJSON(json) {
    return MenuCustomizationFromJSONTyped(json, false);
}
exports.MenuCustomizationFromJSON = MenuCustomizationFromJSON;
function MenuCustomizationFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
        'menuItem': !(0, runtime_1.exists)(json, 'menuItem') ? undefined : json['menuItem'],
        'customizationItem': !(0, runtime_1.exists)(json, 'customizationItem') ? undefined : json['customizationItem'],
        'name': json['name'],
        'description': !(0, runtime_1.exists)(json, 'description') ? undefined : json['description'],
        'minAllowed': !(0, runtime_1.exists)(json, 'minAllowed') ? undefined : json['minAllowed'],
        'maxAllowed': !(0, runtime_1.exists)(json, 'maxAllowed') ? undefined : json['maxAllowed'],
        'multipleQuantities': !(0, runtime_1.exists)(json, 'multipleQuantities') ? undefined : json['multipleQuantities'],
        'sortOrder': !(0, runtime_1.exists)(json, 'sortOrder') ? undefined : json['sortOrder'],
        'options': (json['options'].map(_1.MenuCustomizationItemFromJSON)),
    };
}
exports.MenuCustomizationFromJSONTyped = MenuCustomizationFromJSONTyped;
function MenuCustomizationToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'menuItem': value.menuItem,
        'customizationItem': value.customizationItem,
        'name': value.name,
        'description': value.description,
        'minAllowed': value.minAllowed,
        'maxAllowed': value.maxAllowed,
        'multipleQuantities': value.multipleQuantities,
        'sortOrder': value.sortOrder,
        'options': (value.options.map(_1.MenuCustomizationItemToJSON)),
    };
}
exports.MenuCustomizationToJSON = MenuCustomizationToJSON;

"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.UpdateBrandMapBodyToJSON = exports.UpdateBrandMapBodyFromJSONTyped = exports.UpdateBrandMapBodyFromJSON = void 0;
var runtime_1 = require("../runtime");
var _1 = require("./");
function UpdateBrandMapBodyFromJSON(json) {
    return UpdateBrandMapBodyFromJSONTyped(json, false);
}
exports.UpdateBrandMapBodyFromJSON = UpdateBrandMapBodyFromJSON;
function UpdateBrandMapBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'name': json['name'],
        'slug': !(0, runtime_1.exists)(json, 'slug') ? undefined : json['slug'],
        'bannerImage': !(0, runtime_1.exists)(json, 'bannerImage') ? undefined : json['bannerImage'],
        'defaultLocation': !(0, runtime_1.exists)(json, 'defaultLocation') ? undefined : (0, _1.AddressFromJSON)(json['defaultLocation']),
        'status': (0, _1.BrandMapStatusFromJSON)(json['status']),
        'businesses': !(0, runtime_1.exists)(json, 'businesses') ? undefined : json['businesses'],
        'mainPromo': !(0, runtime_1.exists)(json, 'mainPromo') ? undefined : json['mainPromo'],
        'promos': !(0, runtime_1.exists)(json, 'promos') ? undefined : json['promos'],
        'promoHero': !(0, runtime_1.exists)(json, 'promoHero') ? undefined : json['promoHero'],
        'promoBackground': !(0, runtime_1.exists)(json, 'promoBackground') ? undefined : json['promoBackground'],
        'promoCartHero': !(0, runtime_1.exists)(json, 'promoCartHero') ? undefined : json['promoCartHero'],
        'promoCartBackground': !(0, runtime_1.exists)(json, 'promoCartBackground') ? undefined : json['promoCartBackground'],
        'defaultBusiness': !(0, runtime_1.exists)(json, 'defaultBusiness') ? undefined : json['defaultBusiness'],
        'promoTitle': !(0, runtime_1.exists)(json, 'promoTitle') ? undefined : json['promoTitle'],
        'promoDescription': !(0, runtime_1.exists)(json, 'promoDescription') ? undefined : json['promoDescription'],
        'promoTagline': !(0, runtime_1.exists)(json, 'promoTagline') ? undefined : json['promoTagline'],
        'colorTheme': (0, _1.BrandMapColorThemeFromJSON)(json['colorTheme']),
        'colorBackground': !(0, runtime_1.exists)(json, 'colorBackground') ? undefined : json['colorBackground'],
        'advertisementBlocks': (json['advertisementBlocks'].map(_1.BrandMapAdvertisementBlocksBodyFromJSON)),
    };
}
exports.UpdateBrandMapBodyFromJSONTyped = UpdateBrandMapBodyFromJSONTyped;
function UpdateBrandMapBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'name': value.name,
        'slug': value.slug,
        'bannerImage': value.bannerImage,
        'defaultLocation': (0, _1.AddressToJSON)(value.defaultLocation),
        'status': (0, _1.BrandMapStatusToJSON)(value.status),
        'businesses': value.businesses,
        'mainPromo': value.mainPromo,
        'promos': value.promos,
        'promoHero': value.promoHero,
        'promoBackground': value.promoBackground,
        'promoCartHero': value.promoCartHero,
        'promoCartBackground': value.promoCartBackground,
        'defaultBusiness': value.defaultBusiness,
        'promoTitle': value.promoTitle,
        'promoDescription': value.promoDescription,
        'promoTagline': value.promoTagline,
        'colorTheme': (0, _1.BrandMapColorThemeToJSON)(value.colorTheme),
        'colorBackground': value.colorBackground,
        'advertisementBlocks': (value.advertisementBlocks.map(_1.BrandMapAdvertisementBlocksBodyToJSON)),
    };
}
exports.UpdateBrandMapBodyToJSON = UpdateBrandMapBodyToJSON;

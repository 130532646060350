"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.UpdateBusinessAdminToJSON = exports.UpdateBusinessAdminFromJSONTyped = exports.UpdateBusinessAdminFromJSON = void 0;
var runtime_1 = require("../runtime");
var _1 = require("./");
function UpdateBusinessAdminFromJSON(json) {
    return UpdateBusinessAdminFromJSONTyped(json, false);
}
exports.UpdateBusinessAdminFromJSON = UpdateBusinessAdminFromJSON;
function UpdateBusinessAdminFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return __assign(__assign({}, (0, _1.CreateBusinessBodyBaseFromJSONTyped)(json, ignoreDiscriminator)), { 'firstDeliveryId': !(0, runtime_1.exists)(json, 'firstDeliveryId') ? undefined : json['firstDeliveryId'], 'isEnabled': !(0, runtime_1.exists)(json, 'isEnabled') ? undefined : json['isEnabled'], 'isActive': !(0, runtime_1.exists)(json, 'isActive') ? undefined : json['isActive'], 'isOperating': !(0, runtime_1.exists)(json, 'isOperating') ? undefined : json['isOperating'], 'distanceDeliveryMax': !(0, runtime_1.exists)(json, 'distanceDeliveryMax') ? undefined : json['distanceDeliveryMax'], 'mealmeStoreId': !(0, runtime_1.exists)(json, 'mealmeStoreId') ? undefined : json['mealmeStoreId'] });
}
exports.UpdateBusinessAdminFromJSONTyped = UpdateBusinessAdminFromJSONTyped;
function UpdateBusinessAdminToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return __assign(__assign({}, (0, _1.CreateBusinessBodyBaseToJSON)(value)), { 'firstDeliveryId': value.firstDeliveryId, 'isEnabled': value.isEnabled, 'isActive': value.isActive, 'isOperating': value.isOperating, 'distanceDeliveryMax': value.distanceDeliveryMax, 'mealmeStoreId': value.mealmeStoreId });
}
exports.UpdateBusinessAdminToJSON = UpdateBusinessAdminToJSON;

import React, {ChangeEventHandler, ReactElement, useEffect, useState} from "react";
import BusinessDetailsPageTopBar from "./components/BusinessDetailsPageTopBar";
import {useDispatch, useSelector} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import {useParams} from "react-router";
import {
    Asset,
    AssetsApi,
    Business,
    BusinessesApi,
    BusinessPermission,
    BusinessTaxonomy,
    BusinessType, GetMenuResponse,
    HandoffOptions,
    MenusApi,
    BusinessUserFull, BusinessTaxonomiesApi,
} from "@devour/client";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/metaActions";
import getConfig from "../../utils/getConfig";
import {isAsset, isRestaurant} from "../../utils/typeGuards";
import {UpdatedBusinessBodyFrontend} from "./components/BusinessFormValues";
import BusinessDetailsHeader from "./components/ BusinessDetailsHeader";
import {addURLsToFiles, FileWithSRC} from "../../utils/renderAssetsHelper";
import BusinessDetailsSectionTabs from "./components/BusinessDetailsSectionTabs";
import BusinessDetailsContactInfo from "./components/BusinessDetailsContactInfo";
import BusinessDetailsCategories from "./components/BusinessDetailsCategories";
import BusinessDetailsPayoutSettings from "./components/BusinessDetailsPayoutSettings";
import BusinessDetailsMenus from "./components/BusinessDetailsMenus";
import BusinessDetailsHours from "./components/BusinessDetailsHours";
import BusinessDetailsUserPermissions from "./components/BusinessDetailsUserPermissions";
import BusinessDetailsOrders from "./components/BusinessDetailsOrders";

const defaultValues: UpdatedBusinessBodyFrontend = {
    type: BusinessType.RESTAURANT,
    name: "",
    internalName: "",
    timeZone: "",
    description: "",
    email: "",
    website: "",
    prepTime: 30,
    phoneNumber: {
        countryCode: "US",
        nationalNumber: "",
    },
    address: {
        line1: "",
        line2: "",
        locality: "",
        administrativeArea: "",
        postalCode: "",
        country: "US",
    },
    taxonomies: [],
    handoffOptions: Object.values(HandoffOptions),
    icon: undefined,
    headerImage: undefined,
};

export enum SectionViews {
    CONTACT,
    CATEGORIES,
    MENUS,
    ORDERS,
    USERS,
    HOURS,
    PAYOUTS
}

function BusinessDetailsPage(): ReactElement {
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const dispatch = useDispatch();
    const urlParams = new URLSearchParams(window.location.search);
    const viewParam = urlParams.get('view');

    const {businessId} = useParams<{ businessId: string }>();
    const [business, setBusiness] = useState<Business>();
    const [permissions, setPermissions] = useState<Array<BusinessPermission>>([]);
    const [businessUsers, setBusinessUsers] = useState<Array<BusinessUserFull>>([]);
    const [businessBody, setBusinessBody] = useState<UpdatedBusinessBodyFrontend>(defaultValues);
    const [businessesTaxonomies, setBusinessesTaxonomies] = useState<Array<BusinessTaxonomy>>([]);
    const [restaurantMenu, setRestaurantMenu] = useState<GetMenuResponse>();
    const [sectionView, setSectionView] = useState<SectionViews>(SectionViews.CONTACT);

    useEffect(() => {
        void fetchBusinessTaxonomies();

        if (viewParam === "payouts") {
            setSectionView(SectionViews.PAYOUTS);
        }
    }, []);

    useEffect(() => {
        if (businessId) {
            void fetchBusinessPermissions();
            void fetchBusiness();
            void fetchRestaurantMenu();
        }
    }, [businessId]);

    async function fetchBusiness(): Promise<void> {
        dispatch(incrementLoading());
        try {
            const res = await new BusinessesApi().getBusiness({
                id: businessId,
            });
            setBusiness(res);
            setBusinessBody({
                ...res,
                prepTime: (isRestaurant(res)) ? res.prepTime : undefined,
                handoffOptions: (isRestaurant(res)) ? res.handoffOptions : undefined,
                phoneNumber: {
                    countryCode: res.phoneNumber.countryCode,
                    nationalNumber: res.phoneNumber.nationalNumber,
                },
            });
        } catch (e) {
            dispatch(await addError(e));
        } finally {
            dispatch(decrementLoading());
        }
    }

    async function fetchBusinessPermissions(): Promise<void> {
       dispatch(incrementLoading());

        try {
            const res = await new BusinessesApi(getConfig()).getBusinessPermissions({
                id: businessId,
                limit: Number.MAX_SAFE_INTEGER,
            });
            setBusinessUsers(res.currentUsers);
            setPermissions(res.authUser);
        } catch (e) {
            dispatch(await addError(e));
        } finally {
            dispatch(decrementLoading());
        }
    }

    async function fetchBusinessTaxonomies(): Promise<void> {
        try {
            const res = await new BusinessTaxonomiesApi().getBusinessTaxonomies();
            setBusinessesTaxonomies(res.businessTaxonomies);
        } catch (e) {
        }
    }

    /**
     * Get the menu for this restaurant from our api.
     */
    async function fetchRestaurantMenu(): Promise<void> {
        dispatch(incrementLoading());
        try {
            const res = await new MenusApi(getConfig()).getMenu({
                id: businessId,
            });
            setRestaurantMenu(res);
        } catch (e) {
            dispatch(await addError(e));
        } finally {
            dispatch(decrementLoading());
        }
    }

    function businessBodyOnChange(updatedValues: Partial<UpdatedBusinessBodyFrontend>): void {
        setBusinessBody(prevState => ({...prevState, ...updatedValues}));
    }

    function inputOnChange(key: keyof Omit<UpdatedBusinessBodyFrontend, "icon" | "headerImage">): ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement> {
        return (e) => {
            setBusinessBody({
                ...businessBody,
                [key]: e.target.value,
            })
        }
    }

    function onImageChange(key: keyof Pick<UpdatedBusinessBodyFrontend, "icon" | "headerImage">): ChangeEventHandler<HTMLInputElement> {
        return async (e) => {
            const newAsset = (await addURLsToFiles(e.target.files))[0];
            setBusinessBody({
                ...businessBody,
                [key]: newAsset
            })
        }
    }

    function onImageRemove(key: keyof Pick<UpdatedBusinessBodyFrontend, "icon" | "headerImage">): void {
        setBusinessBody({
            ...businessBody,
            [key]: undefined
        })
    }

    async function submitUpdatedBusinessContactInfo(): Promise<void> {
        dispatch(incrementLoading());
        try {
            let icon: Asset | FileWithSRC = businessBody.icon;
            if (icon && !isAsset(icon)) {
                icon = await new AssetsApi(getConfig(fullToken)).createAsset({
                    asset: icon,
                });
            }

            let headerImage: Asset | FileWithSRC = businessBody.headerImage;
            if (headerImage && !isAsset(headerImage)) {
                headerImage = await new AssetsApi(getConfig(fullToken)).createAsset({
                    asset: headerImage,
                });
            }

            await new BusinessesApi(getConfig()).updateBusinessMerchant({
                id: businessId,
                createBusinessBody: {
                    ...businessBody,
                    prepTime: (businessBody.prepTime) ? Number(businessBody.prepTime) : undefined,
                    taxonomies: businessBody.taxonomies.filter((tid) => businessesTaxonomies.find((taxonomy) => taxonomy.id === tid)),
                    icon: icon ? (isAsset(icon) ? icon.id : (businessBody.icon as Asset)?.id) : undefined,
                    headerImage: headerImage ? (isAsset(headerImage) ? headerImage.id : (businessBody.headerImage as Asset)?.id) : undefined,
                }
            });
            void fetchBusiness();
        } catch (e) {
            dispatch(await addError(e));
        } finally {
            dispatch(decrementLoading());
        }
    }

    function renderTabContent(): ReactElement {
        if (sectionView === SectionViews.CATEGORIES) {
            return (
                <BusinessDetailsCategories
                    businessTaxonomies={businessesTaxonomies}
                    businessBody={businessBody}
                    businessBodyOnChange={businessBodyOnChange}
                    contactPermission={permissions.includes(BusinessPermission.CONTACT)}
                    onSubmitBusiness={submitUpdatedBusinessContactInfo}
                />
            );
        } else if (sectionView === SectionViews.HOURS) {
            return (
              <BusinessDetailsHours
                  business={business}
                  businessBody={businessBody}
                  businessBodyOnChange={businessBodyOnChange}
              />
            );
        } else if (sectionView === SectionViews.PAYOUTS) {
            return (
                <BusinessDetailsPayoutSettings business={business} />
            )
        } else if (sectionView === SectionViews.USERS) {
            return (
                <BusinessDetailsUserPermissions
                    business={business}
                    onUpdateBusinessPermissions={() => fetchBusinessPermissions()}
                />
            )
        } else if (sectionView === SectionViews.MENUS) {
            return (
                <BusinessDetailsMenus
                    business={business}
                    restaurantMenu={restaurantMenu}
                    menuPermission={permissions.includes(BusinessPermission.MENUS)}
                    onUpdateMenu={fetchRestaurantMenu}
                />
            )
        } else if (sectionView === SectionViews.ORDERS) {
            return (
                <BusinessDetailsOrders business={business} />
            )
        }
        
        return (
            <BusinessDetailsContactInfo
                business={business}
                businessBody={businessBody}
                businessBodyOnChange={businessBodyOnChange}
                inputOnChange={inputOnChange}
                contactPermission={permissions.includes(BusinessPermission.CONTACT)}
                onSubmitBusiness={submitUpdatedBusinessContactInfo}
            />
        );
    }

    
    if (!business) {
        return null;
    }

    return(
        <div className="business-details-page">
            <BusinessDetailsPageTopBar
                business={business}
                businessBody={businessBody}
                businessBodyOnChange={businessBodyOnChange}
            />

            <BusinessDetailsHeader
                businessBody={businessBody}
                onImageChange={onImageChange}
                onImageRemove={onImageRemove}
                inputOnChange={inputOnChange}
            />

            <div className="business-page_section-margin">

                <BusinessDetailsSectionTabs
                    businessBody={businessBody}
                    sectionView={sectionView}
                    setSectionView={setSectionView}
                    numOfMenus={restaurantMenu?.menus.length}
                    numOfBusinessUsers={businessUsers?.length}
                />

                {renderTabContent()}

            </div>

        </div>
    )
}

export default BusinessDetailsPage;

"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GameDataToJSON = exports.GameDataFromJSONTyped = exports.GameDataFromJSON = void 0;
var runtime_1 = require("../runtime");
function GameDataFromJSON(json) {
    return GameDataFromJSONTyped(json, false);
}
exports.GameDataFromJSON = GameDataFromJSON;
function GameDataFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'coins': !(0, runtime_1.exists)(json, 'coins') ? undefined : json['coins'],
        'track_played': !(0, runtime_1.exists)(json, 'track_played') ? undefined : json['track_played'],
        'rank': !(0, runtime_1.exists)(json, 'rank') ? undefined : json['rank'],
        'time': !(0, runtime_1.exists)(json, 'time') ? undefined : json['time'],
        'level_played': !(0, runtime_1.exists)(json, 'level_played') ? undefined : json['level_played'],
        'status': !(0, runtime_1.exists)(json, 'status') ? undefined : json['status'],
        'orders_completed': !(0, runtime_1.exists)(json, 'orders_completed') ? undefined : json['orders_completed'],
        'money_earned': !(0, runtime_1.exists)(json, 'money_earned') ? undefined : json['money_earned'],
        'lifetime_money': !(0, runtime_1.exists)(json, 'lifetime_money') ? undefined : json['lifetime_money'],
        'score': !(0, runtime_1.exists)(json, 'score') ? undefined : json['score'],
        'levels': !(0, runtime_1.exists)(json, 'levels') ? undefined : json['levels'],
    };
}
exports.GameDataFromJSONTyped = GameDataFromJSONTyped;
function GameDataToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'coins': value.coins,
        'track_played': value.track_played,
        'rank': value.rank,
        'time': value.time,
        'level_played': value.level_played,
        'status': value.status,
        'orders_completed': value.orders_completed,
        'money_earned': value.money_earned,
        'lifetime_money': value.lifetime_money,
        'score': value.score,
        'levels': value.levels,
    };
}
exports.GameDataToJSON = GameDataToJSON;

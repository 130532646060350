"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.MealmeStoreToJSON = exports.MealmeStoreFromJSONTyped = exports.MealmeStoreFromJSON = void 0;
var runtime_1 = require("../runtime");
var _1 = require("./");
function MealmeStoreFromJSON(json) {
    return MealmeStoreFromJSONTyped(json, false);
}
exports.MealmeStoreFromJSON = MealmeStoreFromJSON;
function MealmeStoreFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !(0, runtime_1.exists)(json, 'id') ? undefined : json['id'],
        'name': json['name'],
        'address': (0, _1.MealmeStoreAddressFromJSON)(json['address']),
        'type': json['type'],
        'description': json['description'],
        'local_hours': (0, _1.MealmeStoreLocalHoursFromJSON)(json['local_hours']),
        'cuisines': json['cuisines'],
        'food_photos': json['food_photos'],
        'logo_photos': json['logo_photos'],
        'store_photos': json['store_photos'],
        'dollar_signs': json['dollar_signs'],
        'pickup_enabled': json['pickup_enabled'],
        'delivery_enabled': json['delivery_enabled'],
        'offers_first_party_delivery': json['offers_first_party_delivery'],
        'offers_third_party_delivery': json['offers_third_party_delivery'],
        'weighted_rating_value': json['weighted_rating_value'],
        'aggregated_rating_count': json['aggregated_rating_count'],
        'supports_upc_codes': json['supports_upc_codes'],
        'phone_number': !(0, runtime_1.exists)(json, 'phone_number') ? undefined : json['phone_number'],
    };
}
exports.MealmeStoreFromJSONTyped = MealmeStoreFromJSONTyped;
function MealmeStoreToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'name': value.name,
        'address': (0, _1.MealmeStoreAddressToJSON)(value.address),
        'type': value.type,
        'description': value.description,
        'local_hours': (0, _1.MealmeStoreLocalHoursToJSON)(value.local_hours),
        'cuisines': value.cuisines,
        'food_photos': value.food_photos,
        'logo_photos': value.logo_photos,
        'store_photos': value.store_photos,
        'dollar_signs': value.dollar_signs,
        'pickup_enabled': value.pickup_enabled,
        'delivery_enabled': value.delivery_enabled,
        'offers_first_party_delivery': value.offers_first_party_delivery,
        'offers_third_party_delivery': value.offers_third_party_delivery,
        'weighted_rating_value': value.weighted_rating_value,
        'aggregated_rating_count': value.aggregated_rating_count,
        'supports_upc_codes': value.supports_upc_codes,
        'phone_number': value.phone_number,
    };
}
exports.MealmeStoreToJSON = MealmeStoreToJSON;

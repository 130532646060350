"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateMenuOrderBodyToJSON = exports.CreateMenuOrderBodyFromJSONTyped = exports.CreateMenuOrderBodyFromJSON = void 0;
var runtime_1 = require("../runtime");
var _1 = require("./");
function CreateMenuOrderBodyFromJSON(json) {
    return CreateMenuOrderBodyFromJSONTyped(json, false);
}
exports.CreateMenuOrderBodyFromJSON = CreateMenuOrderBodyFromJSON;
function CreateMenuOrderBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'business': !(0, runtime_1.exists)(json, 'business') ? undefined : json['business'],
        'deliveryQuoteId': !(0, runtime_1.exists)(json, 'deliveryQuoteId') ? undefined : json['deliveryQuoteId'],
        'tipRestaurant': !(0, runtime_1.exists)(json, 'tipRestaurant') ? undefined : json['tipRestaurant'],
        'tipDelivery': !(0, runtime_1.exists)(json, 'tipDelivery') ? undefined : json['tipDelivery'],
        'address': !(0, runtime_1.exists)(json, 'address') ? undefined : (0, _1.AddressFromJSON)(json['address']),
        'restaurantNotes': !(0, runtime_1.exists)(json, 'restaurantNotes') ? undefined : json['restaurantNotes'],
        'deliveryNotes': !(0, runtime_1.exists)(json, 'deliveryNotes') ? undefined : json['deliveryNotes'],
        'scheduledTime': !(0, runtime_1.exists)(json, 'scheduledTime') ? undefined : json['scheduledTime'],
        'handoff': !(0, runtime_1.exists)(json, 'handoff') ? undefined : (0, _1.HandoffOptionsFromJSON)(json['handoff']),
        'orderItems': !(0, runtime_1.exists)(json, 'orderItems') ? undefined : (json['orderItems'].map(_1.MenuOrderItemFromJSON)),
        'email': !(0, runtime_1.exists)(json, 'email') ? undefined : json['email'],
        'phoneNumber': !(0, runtime_1.exists)(json, 'phoneNumber') ? undefined : (0, _1.PhoneNumberBodyFromJSON)(json['phoneNumber']),
        'firstName': !(0, runtime_1.exists)(json, 'firstName') ? undefined : json['firstName'],
        'lastName': !(0, runtime_1.exists)(json, 'lastName') ? undefined : json['lastName'],
        'paymentMethodId': !(0, runtime_1.exists)(json, 'paymentMethodId') ? undefined : json['paymentMethodId'],
        'dpay': !(0, runtime_1.exists)(json, 'dpay') ? undefined : json['dpay'],
        'vdpay': !(0, runtime_1.exists)(json, 'vdpay') ? undefined : json['vdpay'],
        'onChainDpay': !(0, runtime_1.exists)(json, 'onChainDpay') ? undefined : json['onChainDpay'],
        'dpayFiatAtOrderTime': !(0, runtime_1.exists)(json, 'dpayFiatAtOrderTime') ? undefined : json['dpayFiatAtOrderTime'],
        'isMagicWallet': !(0, runtime_1.exists)(json, 'isMagicWallet') ? undefined : json['isMagicWallet'],
        'discountSelected': !(0, runtime_1.exists)(json, 'discountSelected') ? undefined : json['discountSelected'],
        'discountCodes': !(0, runtime_1.exists)(json, 'discountCodes') ? undefined : json['discountCodes'],
    };
}
exports.CreateMenuOrderBodyFromJSONTyped = CreateMenuOrderBodyFromJSONTyped;
function CreateMenuOrderBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'business': value.business,
        'deliveryQuoteId': value.deliveryQuoteId,
        'tipRestaurant': value.tipRestaurant,
        'tipDelivery': value.tipDelivery,
        'address': (0, _1.AddressToJSON)(value.address),
        'restaurantNotes': value.restaurantNotes,
        'deliveryNotes': value.deliveryNotes,
        'scheduledTime': value.scheduledTime,
        'handoff': (0, _1.HandoffOptionsToJSON)(value.handoff),
        'orderItems': value.orderItems === undefined ? undefined : (value.orderItems.map(_1.MenuOrderItemToJSON)),
        'email': value.email,
        'phoneNumber': (0, _1.PhoneNumberBodyToJSON)(value.phoneNumber),
        'firstName': value.firstName,
        'lastName': value.lastName,
        'paymentMethodId': value.paymentMethodId,
        'dpay': value.dpay,
        'vdpay': value.vdpay,
        'onChainDpay': value.onChainDpay,
        'dpayFiatAtOrderTime': value.dpayFiatAtOrderTime,
        'isMagicWallet': value.isMagicWallet,
        'discountSelected': value.discountSelected,
        'discountCodes': value.discountCodes,
    };
}
exports.CreateMenuOrderBodyToJSON = CreateMenuOrderBodyToJSON;

import React from "react";
import {createRoot} from "react-dom/client";
import {Web3Modal} from "@web3modal/react";
import {Provider} from "react-redux";
import {PersistGate} from "redux-persist/integration/react";
import {persistor, store} from "./redux"
import * as serviceWorker from "./serviceWorker";
import App from "./App";
import {devourConfig} from "./config/web3ModalConfigOptions";
import "./style/index.scss";
import GA4React from "ga-4-react";
import ReactDOM from "react-dom";

const ga4react = new GA4React(
	process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_CODE,
	{
		debug_mode: process.env.NODE_ENV === "development",
	},
	[ /* additional code, optional */ ],
	5000 /* timeout, optional, defaults is 5000 */,
	{} /* { nonce: ['first-script-is-async','second-script'] } */
);

(async () => {
	try {
		await ga4react.initialize();
	} catch (err) {
		console.error("GA init error", err);
	}
	createRoot(container!).render(
		<React.Fragment>
			<Provider store={store}>
				<PersistGate
					persistor={persistor}
					loading={null}
				>
					<App/>
				</PersistGate>
			</Provider>

			<Web3Modal config={devourConfig}/>
		</React.Fragment>
	);
})();
const container = document.getElementById("root");


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();


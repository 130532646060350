"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApiResponseTypeToJSON = exports.ApiResponseTypeFromJSONTyped = exports.ApiResponseTypeFromJSON = exports.ApiResponseType = void 0;
/**
 *
 * @export
 * @enum {string}
 */
var ApiResponseType;
(function (ApiResponseType) {
    ApiResponseType["FIRSTDELIVERYSENDORDER"] = "FIRST_DELIVERY_SEND_ORDER";
    ApiResponseType["FIRSTDELIVERYWEBHOOK"] = "FIRST_DELIVERY_WEBHOOK";
    ApiResponseType["FIRSTDELIVERYSTATUSCALLBACK"] = "FIRST_DELIVERY_STATUS_CALLBACK";
    ApiResponseType["CHOWLYSENDORDER"] = "CHOWLY_SEND_ORDER";
    ApiResponseType["CHOWLYWEBHOOK"] = "CHOWLY_WEBHOOK";
    ApiResponseType["CHOWLYORDERCALLBACK"] = "CHOWLY_ORDER_CALLBACK";
    ApiResponseType["CHOWLYERRORMENU"] = "CHOWLY_ERROR_MENU";
    ApiResponseType["ITSACHECKMATESENDORDER"] = "ITSACHECKMATE_SEND_ORDER";
    ApiResponseType["ITSACHECKMATEWEBHOOK"] = "ITSACHECKMATE_WEBHOOK";
    ApiResponseType["ITSACHECKMATEERRORMENU"] = "ITSACHECKMATE_ERROR_MENU";
    ApiResponseType["ITSACHECKMATEERRORLOCATION"] = "ITSACHECKMATE_ERROR_LOCATION";
    ApiResponseType["ITSACHECKMATEERRORACTIVATE"] = "ITSACHECKMATE_ERROR_ACTIVATE";
    ApiResponseType["ITSACHECKMATEERRORACCESS"] = "ITSACHECKMATE_ERROR_ACCESS";
    ApiResponseType["ITSACHECKMATEERRORREFRESH"] = "ITSACHECKMATE_ERROR_REFRESH";
    ApiResponseType["MEALMEQUOTEORDER"] = "MEALME_QUOTE_ORDER";
    ApiResponseType["MEALMECREATEORDER"] = "MEALME_CREATE_ORDER";
    ApiResponseType["MEALMEFINALIZEORDER"] = "MEALME_FINALIZE_ORDER";
    ApiResponseType["MEALMEWEBHOOK"] = "MEALME_WEBHOOK";
    ApiResponseType["MEALMEORDERCALLBACK"] = "MEALME_ORDER_CALLBACK";
    ApiResponseType["MEALMEREADCHAT"] = "MEALME_READ_CHAT";
    ApiResponseType["MEALMEERRORINVENTORY"] = "MEALME_ERROR_INVENTORY";
    ApiResponseType["MEALMEERRORINVENTORYQUOTE"] = "MEALME_ERROR_INVENTORY_QUOTE";
    ApiResponseType["MEALMEERRORPRODUCTDETAILS"] = "MEALME_ERROR_PRODUCT_DETAILS";
    ApiResponseType["OTHER"] = "OTHER";
})(ApiResponseType = exports.ApiResponseType || (exports.ApiResponseType = {}));
function ApiResponseTypeFromJSON(json) {
    return ApiResponseTypeFromJSONTyped(json, false);
}
exports.ApiResponseTypeFromJSON = ApiResponseTypeFromJSON;
function ApiResponseTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.ApiResponseTypeFromJSONTyped = ApiResponseTypeFromJSONTyped;
function ApiResponseTypeToJSON(value) {
    return value;
}
exports.ApiResponseTypeToJSON = ApiResponseTypeToJSON;

import React, {Dispatch, ReactElement, SetStateAction} from "react";
import {SectionViews} from "../BusinessDetailsPage";
import classNames from "classnames";
import {CreateBusinessBodyFrontend, UpdatedBusinessBodyFrontend} from "./BusinessFormValues";
import {useParams} from "react-router";
import {BusinessType} from "@devour/client";

interface Props {
    sectionView: SectionViews;
    setSectionView: Dispatch<SetStateAction<SectionViews>>;
    businessBody: CreateBusinessBodyFrontend | UpdatedBusinessBodyFrontend;
    numOfBusinessUsers?: number;
    numOfMenus?: number;
}

function BusinessDetailsSectionTabs(props: Props): ReactElement {
    const {businessId} = useParams<{ businessId: string }>();

    return (
        <div className="business-page_section-tabs">
            <div
                className={classNames(
                    "business-page_section-tabs_tab",
                    {"is-active": props.sectionView === SectionViews.CONTACT}
                )}
                onClick={() => props.setSectionView(SectionViews.CONTACT)}
            >
                <span>Contact Info & Details</span>
            </div>
            <div
                className={classNames(
                    "business-page_section-tabs_tab",
                    {"is-active": props.sectionView === SectionViews.CATEGORIES}
                )}
                onClick={() => props.setSectionView(SectionViews.CATEGORIES)}
            >
                <span>
                    Categories
                    <span
                        className="business-page_section-tabs_tab_count">{props.businessBody?.taxonomies.length}</span>
                </span>
            </div>
            {(businessId && props.businessBody?.type === BusinessType.RESTAURANT) && (
                <>
                    <div
                        className={classNames(
                            "business-page_section-tabs_tab",
                            {"is-active": props.sectionView === SectionViews.MENUS}
                        )}
                        onClick={() => props.setSectionView(SectionViews.MENUS)}
                    >
                        <span>
                            Menus
                            {props.numOfMenus && <span className="business-page_section-tabs_tab_count">{props.numOfMenus}</span>}
                        </span>
                    </div>
                    <div
                        className={classNames(
                            "business-page_section-tabs_tab",
                            {"is-active": props.sectionView === SectionViews.ORDERS}
                        )}
                        onClick={() => props.setSectionView(SectionViews.ORDERS)}
                    >
                        <span>Orders</span>
                    </div>
                    <div
                        className={classNames(
                            "business-page_section-tabs_tab",
                            {"is-active": props.sectionView === SectionViews.USERS}
                        )}
                        onClick={() => props.setSectionView(SectionViews.USERS)}
                    >
                        <span>
                            Users
                            <span className="business-page_section-tabs_tab_count">{props.numOfBusinessUsers}</span>
                        </span>
                    </div>
                    <div
                        className={classNames(
                            "business-page_section-tabs_tab",
                            {"is-active": props.sectionView === SectionViews.HOURS}
                        )}
                        onClick={() => props.setSectionView(SectionViews.HOURS)}
                    >
                        <span>Hours</span>
                    </div>
                </>
            )}
            <div
                className={classNames(
                    "business-page_section-tabs_tab",
                    {"is-active": props.sectionView === SectionViews.PAYOUTS}
                )}
                onClick={() => props.setSectionView(SectionViews.PAYOUTS)}
            >
                <span>
                    Payout Settings
                </span>
            </div>

        </div>
    )
}

export default BusinessDetailsSectionTabs;
